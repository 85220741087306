.searchWrapper{
	width: 100%;
	margin: 20px auto 0;
	background-color: rgba(241, 236, 235, 1);
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid rgba(96, 85, 81, 1);
	border-radius: 7px;
	padding: 10px 20px;
	font-size: 30px;
	.searchInp{
		background-color: transparent; 
		color: rgba(96, 85, 81, 1);
		width: 98%;
		border: none;
		font-size: 18px;
       &::placeholder{
				color: rgba(96, 85, 81, 1);
	   }
		&:focus {
			outline: none;
		}
	}
	.searchIcon {
		display: inline-flex;
		color: rgba(96, 85, 81, 1);
	}
}


@media only screen and (max-width: 830px) {
.searchWrapper {
		width: 97%;
	}
}

