#header {
	max-width: 800px;
	margin: 0 auto;

	.headerBgWrapper {
		position: relative;
		display: flex;

		img {
			width: 100%;
			height: 30vw;
			border-radius: 0 0 30px 30px;
			object-fit: cover;

		}

		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			background-color: rgba(0, 0, 0, 0.2);
			width: 100%;
			height: 100%;
			border-radius: 0 0 30px 30px;
		}

		.socialLogoLang {
			width: 80%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: white;
			position: absolute;
			top: 52%;
			left: 50%;
			transform: translate(-50%, -50%);

			.social {
				display: flex;

				.facebook {
					font-size: 25px;
				}

				.instagram {
					font-size: 25px;
					margin-left: 4px;
					border-radius: 50%;
				}
			}

			.logo {
				display: inline-block;
				height: 22vw;
				max-height: 12rem;
				object-fit: contain;
				// filter: brightness(0) invert(1);
			}

			.selectLang {
				border: 2px solid white;
				border-radius: 8px;
				font-size: 15px;
				color: white;
				cursor: pointer;
				padding: 0px 0px 1px 9px !important;

				.css-iohxn1 svg {
					color: blue !important;
				}

				option {
					color: black;
				}
			}
		}
	}
}


@media only screen and (max-width: 566px) {
	#header {
		.headerBgWrapper {
			position: relative;

			.socialLogoLang {
				width: 90%;
			}
		}

		.branchWrapper {
			width: 86%;
		}
	}
}