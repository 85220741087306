#footer {
	max-width: 800px;
	margin: 0 auto;
	padding: 15px 0;
	background-color: rgba(190, 163, 114, 1);

	.footerLogo {
		display: block;
		width: max-content;
		// height: 28px;
		height: 60px;
		margin: 0 auto;

		img {
			// filter: brightness(0) invert(1);
			// width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.looptech {
		margin-top: 15px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: white;

		.prepared {
			font-weight: 500;
			margin-right: 10px;
			font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		}

		.looptechLogo {
			width: 90px;
			pointer-events: none
		}
	}
}