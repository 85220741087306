.branchesBtn{
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 20px auto 30px;
	width: 120px;
	height: 40px;
	background-color: rgba(96, 85, 81, 1);
	border-radius: 8px;
	cursor: pointer;
	.branches{
		border: none;
		background-color: rgba(96, 85, 81, 1);
		color: white;
		font-size: 16px;
		font-weight: 700;
	 }   
	.arrow{
		display: inline-block;
		margin: 2px -9px 0 4px;
		color: white;
		font-size: 24px;
		transition: .5s ease;
		user-select: none;
	}
	.arrowRotate{
		transform: rotate(180deg);
	}
}
	.branchWrapper{
		width: 91%;
		max-height: 0;
		overflow: hidden;
		transition: .5s ease;
		margin: auto;
		.AllOpen,
		.address,
		.call{
			margin-top: 14px;
			display: flex;
			align-items: center;
			font-size: 16px;
			font-weight: 400;
			color: black;
			.phonesWrapper{
				.line{
					margin: 0 5px;
				}
				&:last-child{
					.line{
						display: none;
					}
				}
			}
		.phones{
			color: black;
		}
		
		}
		.iconWrapper{
		  display: flex;
		  justify-content: center;
		  align-items: center;
	       width: 28px;
           height: 28px;
			border-radius: 50%;
			margin-right: 10px;
			background-color: rgba(96, 85, 81, 1);
			color: white;
			.icon{
				font-size: 24px;
			}
		}
	}
	.branchShow{
		max-height: 1000px;
		transition: .5s ease;
	}

