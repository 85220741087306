* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    font-family: "Inter", sans-serif;
}
.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 0;
}

.categoryName {
    display: inline-block;
    padding: 1rem 0.5rem;
    white-space: nowrap;
    font-weight: 600;
    font-size: 12px;
    border-radius: 8px;
    border: 1px solid rgba(96, 85, 81, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(96, 85, 81, 1);
}
.activeCategory {
    text-align: start;
    background-color: rgba(96, 85, 81, 1);
    color: #ffffff;
}

.swiper-wrapper {
    padding: 7px;
}


/* category product sort input chakra style */
/* .css-190gtjn {
    color: #df0b0b !important;
} */
.css-42b2qy {
    width: auto !important;
}

.chakra-select__icon-wrapper svg {
    color: rgba(96, 85, 81, 1) !important;
}

.css-42b2qy svg{
    color: #fff !important;
}


