#category {
	.categoryTitleWrapper {
		background-color: rgba(241, 236, 235, 1);
		margin: 0 auto;
		position: sticky;
		top: 0;
		z-index: 99;
       border-radius: 7px;
		.customClassname {
			width: auto;
		}

	}

	.line {
		display: block;
		margin: 25px auto;
		height: 0.03125rem;
		background-color: #171717;
		opacity: 0.3;
		border: none;
	}

	.WaySort {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: sticky;
		top: 64px;
		z-index: 999;
		background-color: rgba(241, 236, 235, 1);
		border-radius: 7px;
		padding: .5rem .375rem .75rem .375rem;

		.siteWay {
			width: 91%;
			margin: 0 auto;
			display: flex;
			align-items: center;

			.homePage {
				font-size: .75rem;
				font-weight: 500;
				color: rgba(23, 23, 23, 1);
				opacity: 0.5;
			}

			.arrowIcon {
				margin: 2px 4px 0 7px;
			}

			.currentPage {
				font-size: .75rem;
				font-weight: 500;
				color: rgba(23, 23, 23, 1);
				opacity: 0.7;
			}
		}
	}

	.currentPageTitle {
		width: 91%;
		margin: 20px auto;
		color: rgba(96, 85, 81, 1);
		font-family: 'IBM Plex Serif', serif;
		font-size: 1.25rem;
		font-weight: 700;
	}

	.prList {
		width: 91%;
		margin: 0 auto;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
		justify-content: center;
		gap: 2rem;
	}
}