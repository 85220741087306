.sort {
	border: 1px solid rgba(96, 85, 81, 1) !important;
	border-radius: 8px;
	font-weight: 600;
	font-size: 12px !important;
	padding-left: 8px !important;
	background-color: rgba(241, 236, 235, 1) !important;
	color: rgba(96, 85, 81, 1);
	cursor: pointer;
	user-select: none;
	outline: none;

	option {
		font-weight: 600;
	   color: rgba(96, 85, 81, 1);
	   
	}
}